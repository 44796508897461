const isDebugEnvironment = window.location.hostname === "localhost";
const isDevLghEnvironment =
  window.location.hostname === "liveborn-dev-lgh.laerdal.com";
const isTestEnvironment =
  window.location.hostname === "t-liveborn-we-app-liveborn.azurewebsites.net" ||
  window.location.hostname === "liveborn-test.laerdal.com" ||
  window.location.hostname === "liveborn-test-lgh.laerdal.com";
const isLghEnvironment =
  window.location.hostname ===
  "t-liveborn-we-app-liveborn-lgh.azurewebsites.net";

export const ENV_DEBUG = "debug";
export const ENV_LGH_DEV = "lgh_dev";
export const ENV_TEST = "test";
export const ENV_PROD = "prod";
export const ENV_LGH = "lgh";

export const getEnvironment = () => {
  if (isDebugEnvironment) return ENV_DEBUG;
  if (isTestEnvironment) return ENV_TEST;
  if (isLghEnvironment) return ENV_LGH;
  if (isDevLghEnvironment) return ENV_LGH_DEV;
  return ENV_PROD;
};

const BLOB_CONTAINER_DEV = "liveborn-binaries-dev";
const BLOB_CONTAINER_TEST = "liveborn-binaries-test";
const BLOB_CONTAINER_PROD = "liveborn-binaries";
const BLOB_CONTAINER_LGH = "liveborn-binaries-lgh";

const BLOB_ACCOUNT_DEV = "tlivebornwestrawdata";
const BLOB_ACCOUNT_TEST = "tlivebornwestrawdata";
const BLOB_ACCOUNT_PROD = "plivebornwestrawdata";
const BLOB_ACCOUNT_LGH = "tlivebornwestlgh";

export const getBlobConfigurationForEnvironment = () => {
  const env = getEnvironment();

  console.log("getBlobConfigurationForEnvironment() ...");
  console.log("env: ", env);
  const response = {
    accountName: "",
    containerName: "",
  };

  switch (env) {
    case ENV_DEBUG:
      response.containerName = BLOB_CONTAINER_DEV;
      response.accountName = BLOB_ACCOUNT_DEV;
      break;
    case ENV_TEST:
      response.containerName = BLOB_CONTAINER_TEST;
      response.accountName = BLOB_ACCOUNT_TEST;
      break;
    case ENV_LGH:
    case ENV_LGH_DEV:
      response.containerName = BLOB_CONTAINER_LGH;
      response.accountName = BLOB_ACCOUNT_LGH;
      break;
    case ENV_PROD:
    default:
      response.containerName = BLOB_CONTAINER_PROD;
      response.accountName = BLOB_ACCOUNT_PROD;
      break;
  }

  return response;
};
